import Landing from "../components/Landing";
import Whytext from "../components/Whytext";
import Getstarted from "../components/Getstarted";
import SubjectSection from "../components/SubjectSection";

function Home() {
    return (
        <div className="h-fit bg-body">
            <Landing />
            <Whytext />
            {/* <SubjectSection />
            <Getstarted /> */}
        </div>
    );
}

export default Home;

import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";

function SideMenu({ isVisible }) {
    const subMenuAnimate = {
        enter: {
            opacity: 1,
            scaleY: 1,
            rotateX: 0,
            transition: {
                duration: 0.5,
            },
        },
        exit: {
            opacity: 0,
            rotateX: -15,
            scaleY: 0,
            transition: {
                duration: 0.5,
                delay: 0.3,
            },
        },
    };

    const itemAnimate = {
        default: {
            opacity: 0,
            y: -300,
        },
        onEnter: {
            opacity: 1,
            y: 0,
        },
    };

    return (
        <AnimatePresence>
            <motion.div
                key="dropdown"
                initial="exit"
                animate={isVisible ? "enter" : "exit"}
                variants={subMenuAnimate}
                className={`${
                    isVisible ? "flex" : "hidden"
                } top-18 absolute right-0 z-20 h-[28vh] w-2/5 rounded-lg bg-body p-4 shadow-2xl lg:hidden`}
            >
                <div className="flex w-full transition-all">
                    <ul className="flex w-full flex-col items-start justify-end bg-transparent text-xl text-text">
                        {/* <motion.li
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.5 }}
                            className="z-30 w-full text-[1em] transition-all hover:scale-110 hover:bg-gray-200 hover:text-primary"
                        >
                            <a href="/">Home</a>
                        </motion.li> */}
                        {/* <motion.li
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.6 }}
                            className="z-30 w-full transition-all hover:scale-110 hover:bg-gray-200 hover:text-primary"
                        >
                            <a href="/about">About Us</a>
                        </motion.li>
                        <motion.li
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.7 }}
                            className="z-30 w-full transition-all hover:scale-110 hover:bg-gray-200 hover:text-primary"
                        >
                            <a href="/join">Join</a>
                        </motion.li>
                        <motion.li
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.8 }}
                            className="z-30 w-full transition-all hover:scale-110 hover:bg-gray-200 hover:text-primary"
                        >
                            <a href="/join">Contact Us</a>
                        </motion.li> */}
                        {/* <motion.div
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.9 }}
                            className="mx-auto flex flex-col text-center"
                        >
                            <span className="font-libre text-xl italic text-primary">
                                Book Now!
                            </span>
                            <span className="font-libre text-xl italic text-primary">
                                (585) 667-0423
                            </span>
                        </motion.div> */}
                        <motion.li
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.5 }}
                            className="z-30 w-full text-[1em] transition-all hover:scale-110 hover:bg-gray-200 hover:text-primary"
                        >
                            <Link to="/pricing">Pricing</Link>
                        </motion.li>
                        <motion.li
                            initial="default"
                            animate="onEnter"
                            variants={itemAnimate}
                            transition={{ delay: 0.6 }}
                            className="z-30 w-full transition-all hover:scale-110 hover:bg-gray-200 hover:text-primary"
                        >
                            <Link to="/pricing">Sign Up</Link>
                        </motion.li>
                    </ul>
                </div>
            </motion.div>
        </AnimatePresence>
    );
}

export default SideMenu;

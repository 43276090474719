import React from "react";
import student from "../resources/textbook-holds-pencil-writingLarge.png";
// import SearchBar from '../SearchBar'
// import DummyData from '../../Data.json'
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Landing() {
    return (
        <div className="landing relative z-10 m-auto h-[91vh] w-full justify-center overflow-hidden bg-gradient-to-r from-primary to-secondary font-libre shadow-sm">
            <div className="custom-shape-divider-top-1654092087 absolute top-0 left-0 w-full overflow-hidden leading-[0px]">
                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                    fill="#f6f9fd"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill fill-body"
                    ></path>
                </svg>
            </div>
            <div className="m-auto flex w-3/4 flex-col justify-start">
                <motion.div
                    initial={{ x: -300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    className="statement mt-20 flex w-auto flex-col items-center font-poppins text-white lg:absolute lg:top-52 lg:mx-0 lg:items-start"
                >
                    <p className="mb-3 text-center lg:text-left">
                        Ready to upgrade your skills?
                    </p>
                    <h1 className="block w-full text-center font-poppins text-3xl font-semibold leading-tight lg:mb-3 lg:w-1/2 lg:text-left">
                        Unlock your full potential with personalized, one-on-one
                        tutoring.
                    </h1>
                    <p className="mt-5 block text-center text-lg lg:m-0 lg:w-1/2 lg:text-left">
                        Experience tailored lessons designed to boost your
                        confidence and excel academically.
                    </p>
                    <div className="mt-10 flex w-full flex-col justify-between md:flex-row md:justify-start lg:w-1/2">
                        <Link
                            to="/pricing"
                            className="mb-5 flex h-20 w-full items-center justify-center rounded-xl border-2 border-body text-xl font-bold text-body md:mr-5 md:mb-0 md:w-1/2"
                        >
                            Pricing
                        </Link>

                        <Link
                            to="/pricing"
                            className="flex h-20 w-full items-center justify-center rounded-xl bg-primary text-center text-xl font-bold md:w-1/2"
                        >
                            Sign Up
                        </Link>
                    </div>
                    {/* <SearchBar placeholder='Seach Subjects' data={DummyData}/> */}
                </motion.div>
                {/* CIRCLES */}
                <div className="absolute top-[-100px] left-[-110px] z-[-1] h-96 w-96 rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[550px] left-[-70px] z-[-1] h-64 w-64 rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[400px] left-[50px] z-[-1] h-48 w-48 rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[400px] left-[1100px] z-[-1] h-52 w-52 rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[400px] left-[1500px] z-[-1] h-96 w-96 rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[100px] left-[1000px] z-[-1] h-80 w-80 rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[90px] left-[600px] z-[-1] h-[200px] w-[200px] rounded-full bg-white bg-opacity-20"></div>
                <div className="absolute top-[190px] left-[630px] z-[-1] h-[250px] w-[250px] rounded-full bg-white bg-opacity-20"></div>
                <motion.img
                    initial={{ x: 300, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ type: "just", delay: 0.3 }}
                    className="absolute bottom-0 -right-28 hidden aspect-[8495/5663] h-[100%] cst1:block xl:visible"
                    src={student}
                    alt="Girl holding books"
                />
            </div>
        </div>
    );
}

export default Landing;

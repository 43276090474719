import contactImg from "../resources/contactus.svg";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

function Contact() {
    return (
        <div className="flex flex-col w-3/4 relative mx-auto font-poppins font-semibold my-10 text-[hsla(207,48%,22%,1)]">
            <div className=" flex flex-col justify-center w-full z-[2] ml-10 mt-10">
                <span className="text-lg tracking-widest text-secondary">
                    Anytime, anyplace
                </span>
                <h1 className="leading-10 font-semibold text-[3rem] my-3">
                    {" "}
                    Contact us
                    <br />{" "}
                </h1>
                <p className="text-lg font-light leading-normal">
                    We're here for you 24/7
                </p>
            </div>
            <div className="absolute right-0 w-2/3">
                <img src={contactImg} alt="" />
            </div>
            <div className="flex flex-row justify-between items-center z-[2] font-medium mt-10 mb-20 mx-auto w-full">
                <div className="bg-[#f6f9fc] flex flex-col justify-center items-center text-xl w-[344px] h-[305px] rounded-lg mr-48 shadow-xl bg-opacity-70 backdrop-blur-lg drop-shadow-lg">
                    <BsFillTelephoneFill className="text-[#99acc2] text-[40px] mb-5" />
                    <h3 className="mb-5">Call us directly</h3>
                    <span className="mb-5">+1 585 667 0423</span>
                </div>
                <div className="bg-[#f6f9fc] flex flex-col justify-center items-center text-xl w-[344px] h-[305px] rounded-lg shadow-xl bg-opacity-70 backdrop-blur-lg drop-shadow-lg">
                    <MdEmail className="text-[#99acc2] text-[40px] mb-5" />
                    <h3 className="mb-5">Send us mail</h3>
                    <span className="mb-5">support@tutoral.org</span>
                </div>
            </div>
        </div>
    );
}

export default Contact;

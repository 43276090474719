import { useContext } from "react";
import {
    BsChevronLeft,
    BsFillPersonFill,
    BsChevronRight,
} from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { MdSmartphone } from "react-icons/md";
import PricingPackage from "../components/PricingPackage";
import Payment from "../components/Payment";
import contactInfoIllustration from "../resources/contactInfo.svg";
import packageIllustration from "../resources/selectPackage.svg";
import AppointmentPicker from "../components/AppointmentPicker";
import ProgressBar from "../components/ProgressBar";
import FormButton from "../components/FormButton";
import InputField from "../components/InputField";
import { PageContext } from "../context";
import CheckoutForm from "../components/CheckoutForm";

function CheckoutFormTransitions({ stripePromise }) {
    const { page } = useContext(PageContext);
    // const [value, onChange] = useState(new Date());
    // const [input, setInput] = useState("");

    switch (page) {
        case 1:
            return (
                <CheckoutForm
                    page={page}
                    heading="Contact Information"
                    btnColors={[
                        "bg-primary pointer-events-none opacity-60",
                        "bg-primary",
                    ]}
                    btnNames={["Previous", "Next"]}
                >
                    <div className="m-4 flex w-5/12 items-center justify-center">
                        <img
                            src={contactInfoIllustration}
                            alt="Lady fillout out online form."
                            className="w-full fill-primary"
                        />
                    </div>
                    <div className="w-2/5">
                        <InputField
                            type="text"
                            id="parent-name"
                            name="Parent/Guardian Full Name"
                            icon={<BsFillPersonFill />}
                        />
                        <InputField
                            type="text"
                            id="student-name"
                            name="Student Full Name"
                            icon={<BsFillPersonFill />}
                        />
                        <InputField
                            type="email"
                            id="email"
                            name="E-mail"
                            icon={<MdEmail />}
                            pattern="/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
                        />
                        <InputField
                            type="tel"
                            id="phone"
                            name="Phone Number"
                            icon={<MdSmartphone />}
                        />
                        <span className="mt-10 block text-gray-700">
                            Where would you like us to meet you?
                        </span>
                        <div className="flex w-full flex-row items-center justify-evenly">
                            <InputField
                                type="text"
                                id="address-st"
                                name="Street"
                            />
                            <InputField
                                type="text"
                                id="address-city"
                                name="City"
                            />
                            <select
                                id="address-state"
                                className="h-10 border-b-[1px] border-[#ebebeb] bg-body outline-none"
                            >
                                <option
                                    value=""
                                    disabled
                                    selected
                                    className="text-gray-300"
                                >
                                    State
                                </option>
                                <option value="AL">AL</option>
                                <option value="AK">AK</option>
                                <option value="AR">AR</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DC">DC</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="ND">ND</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UT">UT</option>
                                <option value="VT">VT</option>
                                <option value="VA">VA</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>
                            <InputField
                                type="number"
                                id="address-zip"
                                name="ZIP"
                            />
                        </div>
                    </div>
                </CheckoutForm>
            );
        case 2:
            return (
                <CheckoutForm
                    page={page}
                    heading="Choose Your Package"
                    btnColors={["bg-primary", "bg-primary"]}
                    btnNames={["Previous", "Next"]}
                >
                    <div className="flex flex-col justify-center items-center h-full w-full">
                        <div className="flex flex-row w-full items-center justify-center mt-3 h-full">
                            {/* <img
                                src={packageIllustration}
                                alt="Lady fillout out online form."
                                className="w-1/3 fill-primary"
                            /> */}
                            <div className="flex flex-col items-start justify-center mt-12">
                                <label placeholder="Grade Level">
                                    <select
                                        name="grades"
                                        id="grade-select"
                                        required
                                        className="h-10 w-full border-b-[1px] border-[#ebebeb] bg-body outline-none"
                                    >
                                        <option value="">
                                            Student Grade Level
                                        </option>
                                        <option value="0">Kindergarten</option>
                                        <option value="1">1st Grade</option>
                                        <option value="2">2nd Grade</option>
                                        <option value="3">3rd Grade</option>
                                        <option value="4">4th Grade</option>
                                        <option value="5">5th Grade</option>
                                        <option value="6">6th Grade</option>
                                        <option value="7">7th Grade</option>
                                        <option value="8">8th Grade</option>
                                        <option value="9">9th Grade</option>
                                        <option value="10">10th Grade</option>
                                        <option value="11">11th Grade</option>
                                        <option value="12">12th Grade</option>
                                        <option value="13">
                                            College/University
                                        </option>
                                    </select>
                                </label>
                                <label placeholder="Category">
                                    <select
                                        name="category"
                                        id="cat-select"
                                        required
                                        className="h-10 w-full border-b-[1px] border-[#ebebeb] bg-body outline-none"
                                    >
                                        <option value="">
                                            Subject Category
                                        </option>
                                        <option value="math">Math</option>
                                        <option value="science">Science</option>
                                        <option value="english">English</option>
                                        <option value="music">Music</option>
                                    </select>
                                </label>
                                <label placeholder="Subject">
                                    <select
                                        name="subject"
                                        id="sub-select"
                                        required
                                        className="h-10 w-full border-b-[1px] border-[#ebebeb] bg-body outline-none"
                                    >
                                        <option value="">Select Subject</option>
                                        <option value="math">Algebra</option>
                                        <option value="science">
                                            Geometry
                                        </option>
                                        <option value="english">
                                            Trigonometry
                                        </option>
                                        <option value="music">
                                            Statistics
                                        </option>
                                        <option value="music">Calculus</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <PricingPackage className="flex h-full w-full items-center justify-around mb-28" />
                    </div>
                </CheckoutForm>
            );
        case 3:
            return (
                <CheckoutForm
                    page={page}
                    heading="Set Your Appointment"
                    btnColors={["bg-primary", "bg-primary"]}
                    btnNames={["Previous", "Next"]}
                >
                    <div className="flex flex-col h-[80%] justify-center items-center">
                        <span>
                            What days and times would you like your tutor to
                            visit?
                        </span>
                        <span>(These appointments repeat weekly)</span>
                        <AppointmentPicker />
                    </div>
                </CheckoutForm>
            );
        case 4:
            return (
                <CheckoutForm
                    page={page}
                    heading="Checkout"
                    btnColors={["bg-primary", "bg-green-600"]}
                    btnNames={["Previous", "Checkout"]}
                >
                    <Payment stripePromise={stripePromise} />
                </CheckoutForm>
            );
        default:
        // do nothing
    }
}

export default CheckoutFormTransitions;

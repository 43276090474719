import { useEffect, useState } from "react";
import "./styles/App.css";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
// import About from "./pages/About";
import Booking from "./pages/Booking";
import EmployeeLogin from "./pages/EmployeeLogin";
import Completion from "./pages/Completion";
// import NewsAlert from "./components/NewsAlert";
// import AppointmentPicker from "./components/AppointmentPicker";
// import Splash from "./components/Splash";
import Contact from "./pages/Contact";
import PricingPackage from "./components/PricingPackage";
import EmailSignup from "./pages/EmailSignup";
import ThankYou from "./pages/ThankYou";

function App() {
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        fetch("/config").then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });
    }, []);

    if (window.location.host.split(".")[0] === "portal") {
        return (
            <Router>
                <EmployeeLogin />
            </Router>
        );
    }
    return (
        <Router>
            {/* <Splash /> */}
            <div className="App bg-body scrollbar-hide">
                <Navbar />
                {/* <NewsAlert /> */}
                <Routes>
                    <Route path="/" element={<Home />} />
                    {/* <Route path="/about" element={<About />} /> */}
                    <Route
                        path="/completion"
                        element={<Completion stripePromise={stripePromise} />}
                    />
                    <Route
                        path="/book"
                        element={<Booking stripePromise={stripePromise} />}
                    />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/booking" element={<Booking />} />
                    <Route path="/pricing" element={<EmailSignup />} />
                    <Route path="/thankyou" element={<ThankYou />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer className="absolute bottom-0" />
            </div>
        </Router>
    );
}

export default App;

import { useContext } from "react";
import { PageContext } from "../context";

function FormButton({ name, color, iconLeft, iconRight, pageUp, isDisabled }) {
    const { page, setPage } = useContext(PageContext);
    const handleClick = () => {
        switch (pageUp) {
            case true:
                return setPage(page + 1);
            case false:
                return setPage(page - 1);
            case "checkout":
                return console.log("Form Submitted");
            default:
            //do nothing
        }
    };

    return (
        <button
            type="button"
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className={`bg-none p-0 text-white ${color} my-10 flex h-14 w-40 items-center justify-center rounded px-6 py-2.5 text-lg font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:scale-110 hover:bg-opacity-90 hover:shadow-lg focus:bg-opacity-90 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-opacity-90 active:shadow-lg`}
            onClick={handleClick}
        >
            {iconLeft}
            {name}
            {iconRight}
        </button>
    );
}

export default FormButton;

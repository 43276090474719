import { RiHomeHeartFill } from "react-icons/ri";
import { BiHappyBeaming } from "react-icons/bi";
import { VscGraph } from "react-icons/vsc";
// import teaching from '../../resources/pexels-andrea-piacquadio-3769981.jpg'
import teaching from "../resources/tutor_teaching.jpg";
import { motion } from "framer-motion";

function Whytext() {
    return (
        <div className="main-div m-auto mx-10 mt-16 mb-10 flex h-[75vh] w-full justify-center bg-body font-poppins font-semibold text-[hsla(207,48%,22%,1)] md:mb-0 lg:mx-auto lg:w-3/4 lg:justify-around">
            <div className="mx-auto flex w-full flex-col justify-center lg:w-3/4 lg:justify-between">
                <motion.div
                    initial={{ opacity: 0, x: -300 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0, type: "spring", stiffness: 100 }}
                >
                    <h2 className="text-lg tracking-widest text-secondary">
                        Why Tutoral?
                    </h2>
                    <p className="mt-3 text-4xl leading-tight">
                        Personalized
                        <br />
                        <span className="text-primary">Tutoring For All</span>
                        <br /> Students
                    </p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, x: -300 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3, type: "spring", stiffness: 100 }}
                    className="my-4 flex"
                >
                    <div className="mr-5 flex">
                        <VscGraph className="text-5xl text-primary" />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl md:text-2xl">Real Results</p>
                        <p className="w-3/4 text-lg font-light leading-normal">
                            Faster improvement with 1-on-1 lessons from our
                            expert tutors!
                        </p>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: -300 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.35, type: "spring", stiffness: 100 }}
                    className="my-4 flex"
                >
                    <div className="mr-5 flex">
                        <RiHomeHeartFill className="text-5xl text-[#7e76d7]" />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl md:text-2xl">
                            At Your Convenience
                        </p>
                        <p className="w-3/4  text-lg font-light leading-normal">
                            You pick the time and place. No more juggling
                            schedules!
                        </p>
                    </div>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, x: -300 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4, type: "spring", stiffness: 100 }}
                    className="my-4 flex"
                >
                    <div className="mr-5 flex">
                        <BiHappyBeaming className="text-5xl text-[#fe7e41]" />
                    </div>
                    <div className="flex flex-col">
                        <p className="text-xl md:text-2xl">
                            Satisfaction Guarantee
                        </p>
                        <p className="w-3/4 text-lg font-light leading-normal">
                            We're so confident you'll love us, if you're not
                            satisfied your first hour is free!
                        </p>
                    </div>
                </motion.div>
            </div>
            <div className="m-auto hidden h-full w-3/5 flex-col items-center justify-center lg:flex">
                <motion.img
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    className="h-full w-full rounded-2xl object-cover object-left shadow-lg"
                    src={teaching}
                    alt="Girl holding books"
                />
            </div>
        </div>
    );
}

export default Whytext;

import ProgressBar from "./ProgressBar";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import FormButton from "./FormButton";

function CheckoutForm({ children, heading, page, btnColors, btnNames }) {
    return (
        <div className="relative my-10 flex h-[80vh] w-1/2 flex-col items-center justify-center rounded-2xl bg-body">
            <div className="absolute top-0 flex h-20 w-full flex-row items-center justify-between rounded-t-2xl bg-primary text-body">
                <span className="ml-10 inline-block text-2xl">{heading}</span>
                <ProgressBar active={page} />
            </div>
            <div className="flex w-full flex-row items-center justify-center h-full">
                {children}
            </div>
            <div className="absolute bottom-0 flex w-full items-center justify-around">
                <FormButton
                    name={btnNames[0]}
                    iconLeft={<BsChevronLeft />}
                    color={btnColors[0]}
                />
                <FormButton
                    name={btnNames[1]}
                    iconRight={<BsChevronRight />}
                    color={btnColors[1]}
                    pageUp={true}
                />
            </div>
        </div>
    );
}

export default CheckoutForm;

import { useState } from "react";

function AppointmentPicker() {
    const [cellXY, setCellXY] = useState("Times appear here on click");
    const handleClick = (event) => {
        setCellXY(event.currentTarget.id);
    };

    const renderHeadings = () => {
        var headings = [<th> </th>];
        var days = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
        for (var i = 0; i <= days.length - 1; i++) {
            headings.push(
                <th
                    className="px-6 py-4 text-sm font-medium text-body"
                    id={days[i]}
                >
                    {days[i]}
                </th>
            );
        }
        return headings;
    };

    const renderRows = (lower, upper) => {
        var rows = [];
        const times = [];
        for (var i = lower; i <= upper; i++) {
            if (i == 0) {
                times.push("12:00 AM");
            } else if (i < 12) {
                times.push(`${i}:00 AM`);
            } else if (i == 12) {
                times.push("12:00 PM");
            } else {
                times.push(`${i % 12}:00 PM`);
            }
        }
        for (var j = 0; j < times.length; j++) {
            rows.push(
                <tr className="border" id={times[j]}>
                    {renderCells(times[j])}
                </tr>
            );
        }
        return rows;
    };

    const renderCells = (time) => {
        var cells = [];
        cells.push(<td className="border">{time}</td>);
        for (var i = 0; i < 7; i++) {
            cells.push(
                <td
                    className="border"
                    id={`${i + 1}-${time}`}
                    onClick={handleClick}
                ></td>
            );
        }
        return cells;
    };

    return (
        <div className="mx-auto flex h-[60%] w-3/4 flex-col overflow-x-scroll overflow-y-scroll border border-gray-200">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full sm:pl-6 lg:pl-8">
                    <div className="overflow-hidden">
                        <table className="w-full min-w-full table-fixed rounded-lg text-center">
                            <thead className="sticky border-b bg-primary">
                                <tr>{renderHeadings()}</tr>
                            </thead>
                            <tbody>{renderRows(9, 22)}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AppointmentPicker;

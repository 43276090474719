import { useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm(stripe)";

function Payment(props) {
    const { stripePromise } = props;
    const [clientSecret, setClientSecret] = useState("");
    const appearance = {
        theme: "flat",
        variables: {
            colorPrimary: "#1966b3",
            colorBackground: "#eeeeee",
            colorText: "#30313d",
            colorDanger: "#df1b41",
            fontFamily: "Ideal Sans, system-ui, sans-serif",
            spacingUnit: "2px",
            borderRadius: "4px",
            // See all possible variables below
        },
    };

    // useEffect(() => {
    //     // Create PaymentIntent as soon as the page loads
    //     fetch("/create-payment-intent")
    //         .then((res) => res.json())
    //         .then(({ clientSecret }) => setClientSecret(clientSecret))
    //         .then(console.log(clientSecret));
    // }, []);

    return (
        <div className="flex h-auto w-1/3 flex-col items-center justify-center rounded-lg shadow-2xl">
            <h1 className="my-8 ml-3 self-start text-2xl font-medium">
                Enter Payment Info
            </h1>
            {clientSecret && stripePromise && (
                <Elements
                    stripe={stripePromise}
                    options={{ clientSecret, appearance }}
                >
                    <CheckoutForm />
                </Elements>
            )}
            <div></div>
            <div className="mx-auto mb-4 flex w-full items-center justify-start px-2 text-center">
                <p className="text-[10px] text-gray-500">
                    By clicking the "Checkout" button below, you agree to our{" "}
                    <a href="/" className="text-blue-600 hover:text-blue-800">
                        Terms and Conditions
                    </a>
                    ,{" "}
                    <a href="/" className="text-blue-600 hover:text-blue-800">
                        {" "}
                        Privacy Statement
                    </a>
                    , and that the person registering on behalf of a student or
                    themselves are{" "}
                    <span className="font-semibold">over the age of 18.</span>
                </p>
            </div>
        </div>
    );
}

export default Payment;

import { useState } from "react";
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
import { PageContext } from "../context";
import CheckoutFormTransitions from "../components/CheckoutFormTransitions";

// const animateNext = {
//     initial: {
//         opacity: 0,
//         x: 500,
//     },
//     animate: {
//         x: 0,
//         opacity: 1,
//     },
//     exit: {
//         x: -500,
//         opacity: 0,
//     },
//     transition: {
//         duration: 0.5,
//     },
// };

// const animatePrevious = {
//     initial: {
//         opacity: 0,
//         x: -500,
//     },
//     animate: {
//         x: 0,
//         opacity: 1,
//     },
//     exit: {
//         x: 500,
//         opacity: 0,
//     },
//     transition: {
//         duration: 0.5,
//     },
// };

function Booking({ stripePromise }) {
    const [page, setPage] = useState(1);

    return (
        // TODO: Fields needed
        // 1) Parent, Student, Phone, Email
        // 2) Grade, Category, Subject, Pricing Package
        // 3) Address, Datetime, Additonal Info, How did you hear about us?
        // 4) Checkout

        <div className="parent-container flex h-full w-full flex-col items-center justify-center bg-gradient-to-br from-primary to-secondary font-poppins text-textColor">
            <PageContext.Provider value={{ page, setPage }}>
                <CheckoutFormTransitions stripePromise={stripePromise} />
            </PageContext.Provider>
        </div>
    );
}

export default Booking;

import React from "react";

function InputField({ type, icon, id, name, pattern }) {
    return (
        <div className="input-div m-1 my-3 flex h-10 w-full items-center justify-start overflow-hidden border-b-[1px]  border-[#ebebeb] focus-within:border-primary">
            <div className="icon-container flex h-10 w-10 items-center justify-center overflow-hidden">
                {icon}
            </div>
            <label placeholder={name}>
                <input
                    className="input-field h-[2em] w-[250%] bg-body text-[1em] leading-[3em] outline-none"
                    id={id}
                    type={type}
                    placeholder={name}
                    required
                    pattern={pattern}
                />
            </label>
        </div>
    );
}

export default InputField;

import { useFormspark } from "@formspark/use-formspark";
import { useState } from "react";
import { FaChevronRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const FORMSPARK_FORM_ID = "RLiynWk9E";

function EmailSignup() {
    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });

    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        await submit({ message });
        navigate("/thankyou");
    };

    return (
        <div className="mx-auto flex h-auto w-[95%] flex-col justify-start md:h-[105vh] md:w-3/5">
            <div className="mx-auto mt-20 mb-10 flex flex-col justify-center text-center align-middle font-poppins">
                <p className="mb-3 text-[#132130]">
                    SAT Math, Science, and English tutoring
                </p>
                <h1 className="mb-3 block text-3xl font-semibold text-[#010336]">
                    Sign up to get a 25% discount for early customers!
                </h1>
                <p className=" text-[#767785]">
                    Join our waitlist and get access to elite tutoring at great
                    prices
                </p>
            </div>
            <div className="flex h-[30%] flex-col justify-center rounded-3xl bg-[#e6f5fe] align-middle">
                <span className="my-6 text-center text-3xl font-semibold text-[#010336]">
                    Join the waitlist now
                </span>
                <form
                    onSubmit={onSubmit}
                    className="mx-auto flex w-full flex-col justify-center"
                >
                    <div className="mb-10 flex w-[90%] flex-row justify-center self-center rounded-full bg-white p-1 align-middle lg:w-1/2">
                        <input
                            type="email"
                            placeholder="Enter your email address"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            className="mx-auto w-[60%] self-center outline-none lg:w-[75%]"
                            required
                        />
                        <button
                            type="submit"
                            disabled={submitting}
                            className="flex h-[40px] w-[100px] justify-center rounded-full bg-[#293ec1] text-center align-middle text-white transition-all duration-75 hover:scale-110 lg:aspect-square lg:w-[40px]"
                        >
                            <FaChevronRight className=" self-center" />
                        </button>
                    </div>
                </form>
            </div>
            <div className="my-10 flex w-full flex-col justify-between md:my-5 md:h-auto md:flex-row lg:h-[300px] xl:h-[400px]">
                <div className="relative mb-6 flex h-[300px] w-full flex-col items-center justify-center rounded-lg bg-body shadow-md md:h-[310px] md:w-[30%] lg:h-full">
                    <div class="absolute top-0 left-0 w-full overflow-hidden rounded-lg leading-[0px]">
                        <svg
                            className="w-[calc(100% + 1.3px)] relative block h-20 -scale-x-100  fill-secondary opacity-100 transition-all duration-700"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                        </svg>
                        <span className="relative bottom-14 flex w-full items-center justify-center text-2xl font-light tracking-widest text-body transition-all duration-700">
                            <span>BASIC</span>
                        </span>
                    </div>
                    <span className="z-0 my-2 text-xl"> 5 Hours</span>
                    <span className="text-lg text-gray-500 line-through decoration-red-700 decoration-2">
                        $60/HR
                    </span>
                    <div className="flex w-full flex-row items-end justify-center font-light">
                        <span className="self-center">$</span>
                        <span className="mx-2 text-5xl">45</span>
                        <span>/HR</span>
                    </div>
                    <span className="text-center text-xl font-semibold text-secondary">
                        FIRST HOUR FREE
                    </span>
                    <div class="absolute bottom-0 left-0 flex max-w-full rotate-180 flex-col overflow-hidden rounded-t-lg leading-[0px]">
                        <svg
                            className="w-[calc(100% + 1.3px)] block h-72 -scale-x-100 self-end fill-secondary opacity-100 transition-all duration-700"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                        </svg>
                    </div>
                </div>
                <div className="relative mb-6 flex h-[300px] w-full flex-col items-center justify-center rounded-lg bg-body shadow-md md:h-[310px] md:w-[30%] lg:h-full">
                    <div class="absolute top-0 left-0 w-full overflow-hidden rounded-lg leading-[0px]">
                        <svg
                            className="w-[calc(100% + 1.3px)] relative block h-20 -scale-x-100  fill-secondary opacity-100 transition-all duration-700"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                        </svg>
                        <span className="relative bottom-14 flex w-full items-center justify-center text-2xl font-light tracking-widest text-body transition-all duration-700">
                            <span>STANDARD</span>
                        </span>
                    </div>
                    <span className="z-0 my-2 text-xl"> 30 Hours</span>
                    <span className="text-lg text-gray-500 line-through decoration-red-700 decoration-2">
                        $55/HR
                    </span>
                    <div className="flex w-full flex-row items-end justify-center font-light">
                        <span className="self-center">$</span>
                        <span className="mx-2 text-5xl">40</span>
                        <span>/HR</span>
                    </div>
                    <span className="text-center text-xl font-semibold text-secondary">
                        MOST POPULAR
                    </span>
                    <div class="absolute bottom-0 left-0 flex max-w-full rotate-180 flex-col overflow-hidden rounded-t-lg leading-[0px]">
                        <svg
                            className="w-[calc(100% + 1.3px)] block h-72 -scale-x-100 self-end fill-secondary opacity-100 transition-all duration-700"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                        </svg>
                    </div>
                </div>
                <div className="relative mb-6 flex h-[300px] w-full flex-col items-center justify-center rounded-lg bg-body shadow-md md:h-[310px] md:w-[30%] lg:h-full">
                    <div class="absolute top-0 left-0 w-full overflow-hidden rounded-lg leading-[0px]">
                        <svg
                            className="w-[calc(100% + 1.3px)] relative block h-20 -scale-x-100  fill-secondary opacity-100 transition-all duration-700"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                        </svg>
                        <span className="relative bottom-14 flex w-full items-center justify-center text-2xl font-light tracking-widest text-body transition-all duration-700">
                            <span>PREMIUM</span>
                        </span>
                    </div>
                    <span className="z-0 my-2 text-xl"> 180 Hours</span>
                    <span className="text-lg text-gray-500 line-through decoration-red-700 decoration-2">
                        $45/HR
                    </span>
                    <div className="flex w-full flex-row items-end justify-center font-light">
                        <span className="self-center">$</span>
                        <span className="mx-2 text-5xl">33</span>
                        <span>/HR</span>
                    </div>
                    <span className="text-center text-xl font-semibold text-secondary">
                        BEST DEAL
                    </span>
                    <div class="absolute bottom-0 left-0 flex max-w-full rotate-180 flex-col overflow-hidden rounded-t-lg leading-[0px]">
                        <svg
                            className="w-[calc(100% + 1.3px)] block h-72 -scale-x-100 self-end fill-secondary opacity-100 transition-all duration-700"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 1200 120"
                            preserveAspectRatio="none"
                        >
                            <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailSignup;

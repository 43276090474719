import { useContext } from "react";
import { PageContext } from "../context";

function ProgressBar({ active }) {
    const { setPage } = useContext(PageContext);
    return (
        <div className="mr-10 flex w-[35%] flex-row items-center justify-center">
            <div
                className={`${
                    active === 1
                        ? `scale-125 bg-body`
                        : ` bg-[#0d3359] hover:bg-[#134d86]`
                } mx-auto flex h-12 w-12 items-center justify-center rounded-lg text-xl font-bold text-primary transition-all hover:cursor-pointer `}
                onClick={() => setPage(1)}
            >
                1
            </div>
            <div
                className={`${
                    active === 2
                        ? `scale-125 bg-body`
                        : ` bg-[#0d3359] hover:bg-[#134d86]`
                } mx-auto flex h-12 w-12 items-center justify-center rounded-lg text-xl font-bold text-primary transition-all hover:cursor-pointer `}
                onClick={() => setPage(2)}
            >
                2
            </div>
            <div
                className={`${
                    active === 3
                        ? `scale-125 bg-body`
                        : ` bg-[#0d3359] hover:bg-[#134d86]`
                } mx-auto flex h-12 w-12 items-center justify-center rounded-lg text-xl font-bold text-primary transition-all hover:cursor-pointer `}
                onClick={() => setPage(3)}
            >
                3
            </div>
            <div
                className={`${
                    active === 4
                        ? `scale-125 bg-body`
                        : ` bg-[#0d3359] hover:bg-[#134d86]`
                } mx-auto flex h-12 w-12 items-center justify-center rounded-lg text-xl font-bold text-primary transition-all hover:cursor-pointer `}
                onClick={() => setPage(4)}
            >
                4
            </div>
        </div>
    );
}

export default ProgressBar;

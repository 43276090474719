import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { login, reset } from "../features/auth/authSlice";
import Spinner from "../components/Spinner";

function EmployeeLogin() {
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });

    const { email, password } = formData;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    );

    useEffect(() => {
        if (isError) {
            toast.error(message);
        }

        if (isSuccess || user) {
            navigate("/time");
        }

        dispatch(reset());
    }, [user, isError, isSuccess, message, navigate, dispatch]);

    const onChange = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const userData = {
            email,
            password,
        };

        dispatch(login(userData));
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div className="flex h-screen w-full flex-col items-center justify-center bg-green-to-blue">
            <div className="m-auto flex h-96 w-1/3 flex-col items-center justify-center rounded-3xl bg-slate-100 shadow-lg">
                <h1 className="relative top-[-20px] font-poppins font-semibold text-[#134270]">
                    Tutoral Employee Portal
                </h1>
                <form
                    className="flex flex-col items-center justify-center"
                    onSubmit={onSubmit}
                >
                    <label htmlFor="employeeEmail" placeholder="E-mail">
                        <input
                            className="mb-6 h-9 w-fit rounded-full border-[1px] border-solid border-black indent-2.5 transition-all focus:scale-110"
                            type="email"
                            id="employeeEmail"
                            placeholder="E-mail"
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                        />
                    </label>
                    <label htmlFor="employeePassword" placeholder="Password">
                        <input
                            className="mb-6 h-9 w-fit rounded-full border-[1px] border-solid border-black indent-2.5 transition-all focus:scale-110"
                            type="password"
                            id="employeePassword"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={onChange}
                            required
                        />
                    </label>
                    <input
                        className="m-auto h-9 w-2/3 cursor-pointer rounded-full bg-[#2E7DCA] text-[#FEFEFE] outline-none transition-all hover:scale-110"
                        type="submit"
                        value="Log In"
                    />
                </form>
            </div>
        </div>
    );
}

export default EmployeeLogin;

import thanksimg from "../resources/Thankyou.png";
import Confetti from "react-confetti";
import { useState, useEffect } from "react";

function ThankYou() {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }, 100);
    });

    return (
        <div className="flex h-[100vh] w-full flex-col items-center justify-center bg-[#068ec0]">
            <Confetti width={width} height={height} numberOfPieces={450} />
            <img src={thanksimg} alt="Thank you!" />
            {/* <h2>Thank you!</h2> */}
            <p className="mt-3 text-center text-3xl font-semibold text-body">
                Thank you for choosing Tutoral!
            </p>
            <p className="my-6 text-center text-xl text-[#dfdbdb]">
                We're thrilled to have you on board. Get ready for an
                exceptional experience ahead!
            </p>
        </div>
    );
}

export default ThankYou;

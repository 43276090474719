import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
// import './Navbar.css';
// import Signin from '../Signin/Signin';
import { ReactComponent as TutoralLogo } from "../resources/TutoralLogo.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import SideMenu from "../components/SideMenu";
import { RiCloseFill } from "react-icons/ri";
import { Link } from "react-router-dom";

function Navbar() {
    const [navbar, setNavbar] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", changeBackground);
        return () => {
            window.removeEventListener("scroll", changeBackground);
        };
    }, []);

    const changeBackground = () => {
        if (window.scrollY >= 10) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    const [isVisible, setIsVisible] = useState(false);

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1,
            },
        },
    };

    const item = {
        hidden: { opacity: 0, y: -300 },
        show: { opacity: 1, y: 0 },
    };

    return (
        <div
            className={`${
                navbar ? "shadow-lg" : ""
            } sticky top-0 z-20 h-16 w-full flex-row items-center justify-between bg-body pb-1`}
        >
            <div className=" m-auto flex h-16 w-[90%] items-center justify-between md:w-3/4">
                <Link
                    to="/"
                    className="mx-auto flex w-full items-center justify-center text-primary md:mx-0 md:w-auto md:justify-start"
                >
                    <div>
                        <TutoralLogo
                            fill="#0b67c3"
                            className="inline-block h-12 w-auto"
                        />
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <span
                            className={
                                navbar
                                    ? "font-libre text-3xl font-thin text-primary"
                                    : "font-libre text-3xl font-thin text-primary"
                            }
                        >
                            TUTORAL
                        </span>

                        <span
                            className={
                                navbar
                                    ? "text-md font-libre italic"
                                    : "text-md font-libre italic"
                            }
                        >
                            Long Live Learning.
                        </span>
                    </div>
                </Link>
                <nav className="hidden w-1/4 items-center justify-end sm:visible md:flex lg:justify-center">
                    <motion.ul
                        variants={container}
                        initial="hidden"
                        animate="show"
                        className="hidden w-full items-center justify-evenly bg-transparent text-text lg:flex"
                    >
                        {/* <motion.li
                            variants={item}
                            className="z-30 transition-all hover:scale-110 hover:text-primary"
                        >
                            <a href="/">Home</a>
                        </motion.li> */}
                        {/* <motion.li
                            variants={item}
                            className="z-30 transition-all hover:scale-110 hover:text-primary"
                        >
                            <a href="/about">About Us</a>
                        </motion.li>
                        <motion.li
                            variants={item}
                            className="z-30 transition-all hover:scale-110 hover:text-primary"
                        >
                            <a href="/join">Join</a>
                        </motion.li> */}
                        {/* <motion.li
                            variants={item}
                            className="z-30 transition-all hover:scale-110 hover:text-primary"
                        >
                            <a href="/contact">Contact Us</a>
                        </motion.li> */}
                        {/* <motion.div
                            initial={{ opacity: 0, scale: 2 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ delay: 1, duration: 0.5 }}
                            className="flex flex-col text-center"
                        >
                            <span className="font-libre text-xl italic text-primary">
                                Book Now!
                            </span>
                            <span className="font-libre text-xl italic text-primary">
                                (585) 667-0423
                            </span>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, scale: 2 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ delay: 1, duration: 0.5 }}
                            className="flex flex-col text-center"
                        >
                            <span className="font-libre text-lg">
                                tutoral@tutoral.org
                            </span>
                        </motion.div> */}
                        <motion.li
                            variants={item}
                            className="z-30 transform transition-all hover:scale-110 hover:text-primary"
                        >
                            <Link to="/pricing">Pricing</Link>
                        </motion.li>
                        <motion.li
                            variants={item}
                            className="z-30 transform rounded-md bg-primary p-2 px-4 text-white transition-all hover:scale-110 hover:bg-cyan-600"
                        >
                            <Link to="/pricing">Sign Up</Link>
                        </motion.li>
                    </motion.ul>
                    {/* <motion.div
                        initial={{ opacity: 0, scale: 2 }}
                        animate={{ opacity: 1, scale: 1 }}
                    >
                        {isVisible ? (
                            <div
                                className="flex cursor-pointer items-center justify-center transition-all hover:scale-125"
                                onClick={() => setIsVisible(!isVisible)}
                            >
                                <RiCloseFill className="self-end text-3xl text-primary transition-all lg:hidden" />
                                <span className="text-lg text-primary lg:hidden">
                                    CLOSE
                                </span>
                            </div>
                        ) : (
                            <GiHamburgerMenu
                                className="cursor-pointer self-end text-3xl text-primary transition-all hover:scale-125 lg:hidden"
                                onClick={() => setIsVisible(!isVisible)}
                            />
                        )}
                        <SideMenu isVisible={isVisible} />
                    </motion.div> */}
                </nav>
            </div>
        </div>
    );
}

export default Navbar;

{
    /* <li>
                        <a id="signin" href="/">Sign In</a> 
                    </li> */
}
{
    /* <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            // id='signin'
                            onClick={() => null}
                            className='flex items-center justify-center bg-body text-primary rounded-full w-40 p-2 h-auto border-primary border-2 border-solid hover:bg-primary hover:text-white transition-all hover:border-primary'>
                            <a href="/book">Book Online</a>

                        </motion.button> */
}

// {navbar ? 'font-poppins flex justify-end w-full items-center sticky top-0 h-16 bg-body pb-1 z-20 shadow-lg' : 'font-poppins flex justify-end w-full items-center sticky top-0 h-16 pb-1 z-[10] bg-body'}

import { useState } from "react";

function PricingPackage({ className }) {
    return (
        <div className={className}>
            <PricingCard planName={"BASIC"} hours={5} price={60} />
            <PricingCard planName={"STANDARD"} hours={30} price={55} />
            <PricingCard planName={"PREMIUM"} hours={180} price={45} />
        </div>
    );
}

function PricingCard({ planName, price, hours, reducedCost }) {
    const [hoverState, setHoverState] = useState(false);
    const [selected, setSelected] = useState(false);
    const basePrice = 60;

    return (
        <div
            className={`${
                selected ? "" : ""
            } relative flex h-full w-[30%] cursor-pointer flex-col items-center justify-center rounded-lg bg-body shadow-md transition-all duration-500 hover:scale-110 hover:shadow-2xl`}
            onMouseEnter={() => setHoverState(true)}
            onMouseLeave={() => setHoverState(false)}
            onClick={() => setSelected((selected) => !selected)}
        >
            <div class="absolute top-0 left-0 w-full overflow-hidden rounded-lg leading-[0px]">
                <svg
                    className={`${
                        hoverState || selected
                            ? "fill-secondary opacity-100"
                            : " fill-black opacity-20"
                    } w-[calc(100% + 1.3px)] relative block  h-20 -scale-x-100 transition-all duration-700`}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                </svg>
                <span
                    className={`${
                        hoverState || selected ? "text-body " : "text-textcolor"
                    } relative bottom-14 flex w-full items-center justify-center text-2xl font-light tracking-widest transition-all duration-700`}
                >
                    {planName}
                </span>
            </div>
            <span className="my-2 text-xl">{hours} HOURS</span>
            <span className="text-lg text-gray-500 line-through decoration-red-700 decoration-2">
                {planName === "BASIC" ? "" : `$${hours * basePrice}`}{" "}
            </span>
            <span className="text-xl">
                {planName === "BASIC"
                    ? `$${hours * basePrice}`
                    : `$${hours * price}`}
            </span>
            <div className="flex w-full flex-row items-end justify-center font-light">
                <span className="self-center">$</span>
                <span className="mx-2 text-5xl">{price}</span>
                <span>{hours > 1 ? "/HR" : "/HR"}</span>
            </div>
            {planName === "STANDARD" && (
                <span className="text-xl font-semibold text-secondary">
                    MOST POPULAR
                </span>
            )}
            {planName === "BASIC" && (
                <span className="w-full text-center text-xl font-semibold text-secondary">
                    FIRST HOUR FREE
                </span>
            )}
            {planName === "PREMIUM" && (
                <span className="w-full text-center text-xl font-semibold text-secondary">
                    BEST DEAL
                </span>
            )}
            <div class="absolute bottom-0 left-0 flex max-w-full rotate-180 flex-col overflow-hidden rounded-t-lg leading-[0px]">
                <svg
                    className={`${
                        hoverState || selected
                            ? "fill-secondary opacity-100"
                            : " fill-black opacity-20"
                    } w-[calc(100% + 1.3px)] block h-72 -scale-x-100 self-end transition-all duration-700 `}
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                    preserveAspectRatio="none"
                >
                    <path d="M1200 120L0 16.48 0 0 1200 0 1200 120z"></path>
                </svg>
            </div>
        </div>
    );
}
export default PricingPackage;
